import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router/clean";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import VueHotjar from "vue-hotjar-next";
import Toast, { PluginOptions, POSITION } from "vue-toastification";

import "vue-toastification/dist/index.css";

import "@/core/plugins/prismjs";
import "bootstrap";
import "./gtm.js";
const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(VueHotjar, {
  id: 3298491,
  isProduction: true,
  snippetVersion: 6,
});
const toastOptions: PluginOptions = {
  position: POSITION.TOP_LEFT,
};
app.use(Toast, toastOptions);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");
