enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  Register_User = "registerUser",
  Set_Register = "setRegister",
  Login_Getir = "loginGetir",
  Login_Trendyol = "loginTrendyol",
  Login_YemeksepetiNew = "loginYemeksepetiNew",
  Send_Code = "sendCode",
  Check_Hash = "checkHash",
  Get_Products_Getir = "getProductsGetir",
  Get_Products_Data = "getProductsData",
  Change_Getir_Status = "changeGetirStatus",
  Change_Trendyol_Status = "changeTrendyolStatus",
  Change_newYS_Status = "changeNewYSStatus",
  Get_Data = "getData",
  Set_Combined_Products = "setCombinedProducts",
  Get_Mongo_Products = "getMongoProducts",
  Delete_Mongo_Products = "deleteMongoProducts",
  SET_BRANCHES = "setBranches",
  GET_BRANCH = "getBranches",
  SET_MONGO_LOG = "setMongoLog",
  UPDATE_PASSWORD = "updatePassword",
  VERIFY_PASSWORD = "verifyPassword",
  GETIR_CHECK = "getirCheck",
  TRENDYOL_CHECK = "trendyolCheck",
  YEMEKSEPETI_CHECK = "yemeksepetiCheck",
  MIGROS_CHECK = "migrosCheck",
  PAKETSERVISI_CHECK = "paketservisiCheck",
  COMPLETE_REGISTER = "completeRegister",
  PRODUCTS = "products",
  MERGE_PRODUCTS = "mergeProducts",
  UPDATE_STATUS = "updateStatus",
  COMMENTS = "comments",
  OPTIONS = "options",
  CHANGEOPTIONSSTATUS = "changeoptionsstatus",
  FORGOTREGISTER = "forgotregister",
  FORGOTVERIFY = "forgotverify",
  GETCLOSEPRODUCTS = "getcloseproducts",
  GETOPENPRODUCTS = "getopenproducts",
  ADDNEWCREDENTIAL = "addnewcredential",
  REPLYCOMMENT = "replycomment",
  GETINTEGRATIONS = "getintegrations",
  UPDATE_INTEGRATION = "updateIntegration",
  DELETE_INTEGRATION = "deleteIntegration",
  FETCH_PROFILEDETAILS = "fetchProfileDetails",
  GETLOGS = "getLogs",
  UPDATE_PRICE = "updatePrice",
  MAKEPAYMENT = "MAKEPAYMENT"
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  Set_Product_Data = "setProductData",
  SET_PHONE = "setPhone",
  SET_LOGİN_DATA = "setLogİnData",
  SET_BRANCHES_NAMES = "setBranchesNames",
  LOGOUT = "logout",
  SET_PROFILEDETAILS = "setProfileDetails",
  SET_INTEGRATIONS = "setIntegrations"
}

export { Actions, Mutations };
