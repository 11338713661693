import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { getCookie, setCookie, removeCookie } from "typescript-cookie";
import { ElLoading } from "element-plus";

import axios from "axios";

const httpvalue = process.env.VUE_APP_API_URL;

import ls from "local-storage";
import createPersistedState from "vuex-persistedstate";
import router from "@/router";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { ProductNGDTO } from "@/dtos/ProductNGDTO";
import { ProductStatus } from "@/dtos/ProductStatus";
import { IntegrationProduct } from "@/dtos/IntegrationProduct";
import { Integration } from "@/dtos/Integration";
import { PaymentInfo } from "@/dtos/PaymentInfo";
const toast = useToast();

export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  api_token: string;
}
export interface ProfileDetails {
  optionsCount?: number;
  productCount?: number;

  phoneNumber?: string;

  nameSurname?: string;

  address?: string;

  email?: string;

  companyName?: string;

  taxNumber?: string;

  taxOffice?: string;

  branchName?: string;

  locationlat?: number;

  locationlon?: number;

  cousine?: string;

  city: string;

  district: string;

  imageUrl: string;

  town: string;

  companyAddress: string;

  iban: string;
}
@Module
export default class AuthModule extends VuexModule {
  errors = {};
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();
  Products = null;
  phone = "";
  profileDetails = {} as ProfileDetails;
  integrations = [] as Integration[];
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }
  get getIntegrations() {
    return this.integrations;
  }

  get getErrors() {
    return this.errors;
  }
  get getProfileDetails() {
    return this.profileDetails;
  }

  @Action
  [Actions.FETCH_PROFILEDETAILS]() {
    if (!this.profileDetails || this.profileDetails.branchName == null) {
      const token = getCookie('access_token');
      const header = {
        headers: { Authorization: `Bearer ${token}` },
      };
      try {
        axios.get(httpvalue + '/users/profiledetails', header).then((res) => {
          if (res.status == 200) {
            this.context.commit(Mutations.SET_PROFILEDETAILS, res.data);
          } else {
            console.log('no profile data');
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  @Mutation
  [Mutations.SET_PROFILEDETAILS](newProfileDetails: ProfileDetails) {
    this.profileDetails = newProfileDetails;
  }
  @Mutation
  [Mutations.SET_INTEGRATIONS](newIntegrations: Integration[]) {
    this.integrations = newIntegrations;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }
  @Action
  [Actions.CHANGEOPTIONSSTATUS](obj: any) {
    return new Promise((resolve, rejects) => {
      const token = getCookie("access_token");
      const header: any = {
        headers: { Authorization: `Bearer ${token}` },
      };
      axios
        .post(httpvalue + "/products/updateOptionsStatus", obj, header)
        .then((res) => {
          console.log("resresresresresresres");
          console.log(res);
          if (res.status == 201) {
            resolve(201);
          } else {
            resolve(res.status);
          }
        })
        .catch((e: any) => {
          if (!e.status) {
            resolve(501);
          } else {
            resolve(500);
          }
        });
    });
  }
  @Action
  [Actions.OPTIONS]() {
    return new Promise((resolve, rejects) => {
      const token = getCookie("access_token");
      const header: any = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const loading = ElLoading.service({
        lock: true,
        text: "Yükleniyor...",
        background: "rgba(0, 0, 0, 0.7)",
      });
      axios
        .get(httpvalue + "/products/options", header)
        .then((res) => {
          if (res.status == 200) {
            toast.success(
              `${res.data.options.length} adet opsiyon başarıyla yüklendi`
            );
            if (res.data.errors && res.data.errors.length > 0) {
              res.data.errors.forEach((error: any) => {
                toast.error(error);
              });
            }
            loading.close();
            resolve(res);
          } else {
            loading.close();
            resolve(500);
          }
        })
        .catch((e: any) => {
          if (!e.status) {
            loading.close();
            resolve(501);
          } else {
            loading.close();
            resolve(500);
          }
        });
    });
  }
  @Action
  [Actions.COMMENTS]() {
    return new Promise((resolve, reject) => {
      const token = getCookie("access_token");
      const header: any = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const loading = ElLoading.service({
        lock: true,
        text: "Yükleniyor...",
        background: "rgba(0, 0, 0, 0.7)",
      });
      axios
        .get(httpvalue + "/products/comments", header)
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            toast.success(
              `${res.data.comments.length} adet yorum başarıyla yüklendi`
            );
            if (res.data.errors && res.data.errors.length > 0) {
              res.data.errors.forEach((error: any) => {
                toast.error(error);
              });
            }
            resolve(res);
          } else {
            resolve(res.data.statusCode);
          }
          loading.close();
        })
        .catch(async (e) => {
          if (!e.status) {
            resolve(502);
          } else {
            if (e.response.status == 401) {
              reject(e.response.status);
            } else {
              reject(500);
            }
          }
          loading.close();
        });
    });
  }
  @Action
  [Actions.Register_User](obj: any) {
    return new Promise((resolve, reject) => {
      axios
        .post(httpvalue + "/users/register", obj)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          // this.setlogerrormetotd(e,"Register_User error ");
          reject(e);
        });
    });
  }
  @Action
  [Actions.VERIFY_PASSWORD](obj: any) {
    return new Promise((resolve, reject) => {
      const header = {
        headers: { Authorization: `Bearer ${obj.token}` },
      };
      axios
        .post(httpvalue + "/users/verifyPassword", obj, header)
        .then((res) => {
          if (res.status == 401) {
            router.push({ name: "sign-in" });
          } else {
            resolve(res);
          }
        })
        .catch((e) => {
          //this.setlogerrormetotd(e,"Register_User error ");
          reject("error");
        });
    });
  }
  @Action
  [Actions.GETIR_CHECK](obj: any) {
    return new Promise((resolve, reject) => {
      axios
        .post(httpvalue + "/integration/getirCheck", obj)
        .then((result: any) => {
          resolve(result);
        })
        .catch(async (e) => {
          if (e.response.status == 500) {
            resolve(500);
          } else {
            resolve(e.response.status + " Error");
          }
        });
    });
  }
  @Action
  [Actions.TRENDYOL_CHECK](obj: any) {
    return new Promise((resolve, reject) => {
      console.log(obj);
      axios
        .post(httpvalue + "/integration/trendyolCheck", obj)
        .then((result: any) => {
          resolve(result);
        })
        .catch(async (e) => {
          if (e.response.status == 500) {
            resolve(500);
          } else {
            resolve(e.response.status + " Error");
          }
        });
    });
  }
  @Action
  [Actions.YEMEKSEPETI_CHECK](obj: any) {
    return new Promise((resolve, reject) => {
      axios
        .post(httpvalue + "/integration/yemeksepetiCheck", obj)
        .then((result: any) => {
          resolve(result);
        })
        .catch(async (e) => {
          if (e.response.status == 500) {
            resolve(500);
          } else {
            resolve(e.response.status + " Error");
          }
        });
    });
  }
  @Action
  [Actions.MIGROS_CHECK](obj: any) {
    return new Promise((resolve, reject) => {
      axios
        .post(httpvalue + "/integration/migrosCheck", obj)
        .then((result: any) => {
          resolve(result);
        })
        .catch(async (e) => {
          if (e.response.status == 500) {
            resolve(500);
          } else {
            resolve(e.response.status + " Error");
          }
        });
    });
  }
  @Action
  [Actions.PAKETSERVISI_CHECK](obj: any) {
    return new Promise((resolve, reject) => {
      axios
        .post(httpvalue + "/integration/paketservisiCheck", obj)
        .then((result: any) => {
          resolve(result);
        })
        .catch(async (e) => {
          if (e.response.status == 500) {
            resolve(500);
          } else {
            resolve(e.response.status + " Error");
          }
        });
    });
  }
  @Action
  [Actions.COMPLETE_REGISTER](obj: any) {
    return new Promise((resolve, reject) => {
      const header = {
        headers: { Authorization: `Bearer ${obj.token}` },
      };
      delete obj["token"];
      axios
        .post(httpvalue + "/users/completeRegister", obj, header)
        .then((res: any) => {
          if (res.status == 401) {
            router.push({ name: "sign-in" });
          } else {
            resolve(res);
          }
        })
        .catch(async (e) => {
          if (e.response.status == 401) {
            resolve("Authentication Error");
          } else if (e.response.status == 500) {
            resolve("Internal Server Error");
          } else {
            resolve(e.response.status + " Error");
          }
        });
    });
  }
  @Action
  [Actions.FORGOTREGISTER](payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(httpvalue + "/users/forgotregister", payload)
        .then((res: any) => {
          if (res.status == 201) {
            resolve(res);
          } else {
            resolve(500);
          }
        })
        .catch((e: any) => {
          if (!e.status) {
            resolve(502);
          } else {
            if (e.response.status == 401) {
              reject(e.response.status);
            } else {
              reject(500);
            }
          }
        });
    });
  }
  @Action
  [Actions.FORGOTVERIFY](payload) {
    return new Promise((resolve, reject) => {
      const header = {
        headers: { Authorization: `Bearer ${payload.token}` },
      };
      axios
        .post(httpvalue + "/users/updatepassword", payload, header)
        .then((res) => {
          if (res.status == 201) {
            resolve(res);
          } else {
            resolve(500);
          }
        })
        .catch((e) => {
          resolve(e.response.status);
        });
    });
  }
  @Action
  [Actions.LOGIN](payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(httpvalue + "/users/login", payload)
        .then((res) => {
          console.log(res);
          if (res.status == 201) {
            setCookie("access_token", res.data.access_token);
            setCookie("refresh_token", res.data.refresh_token);
          }
          resolve(res);
        })
        .catch(async (e) => {
          console.log(e.response.status);
          if (e.response.status == 401) {
            resolve("Authentication Error");
          } else if (e.response.status == 500) {
            resolve("Internal Server Error");
          } else {
            resolve(e.response.status + " Error");
          }
        });
    });
  }
  @Action
  [Actions.PRODUCTS](token: string) {
    return new Promise((resolve, reject) => {
      const gettoken = getCookie("access_token");

      const header = {
        headers: { Authorization: `Bearer ${gettoken}` },
      };
      const loading = ElLoading.service({
        lock: true,
        text: "Yükleniyor...",
        background: "rgba(0, 0, 0, 0.7)",
      });
      axios
        .get(httpvalue + "/products/all", header)
        .then((res) => {
          //console.log(res);
          if (res.status == 200) {
            toast.success(
              `${res.data.products.length} adet ürün başarıyla yüklendi`
            );
            if (res.data.errors && res.data.errors.length > 0) {
              res.data.errors.forEach((error: any) => {
                toast.error(error);
              });
            }
            resolve(res);
          } else {
            resolve(res.data.statusCode);
          }
          loading.close();
        })
        .catch(async (e) => {
          if (!e.status) {
            resolve(502);
          } else {
            if (e.response.status == 401) {
              reject(e.response.status);
            } else {
              reject(500);
            }
          }
          loading.close();
        });
    });
  }
  @Action
  [Actions.MERGE_PRODUCTS](obj: any) {
    return new Promise((resolve, reject) => {
      const header = {
        headers: { Authorization: `Bearer ${obj.token}` },
      };

      axios
        .post(httpvalue + "/products/merge", obj.product, header)
        .then((res) => {
          if (res.status == 401) {
            router.push({ name: "sign-in" });
          } else {
            resolve(res);
          }
        })
        .catch(async (e) => {
          if (!e.status) {
            resolve(502);
          } else {
            if (e.response.status == 401) {
              resolve(401);
            } else if (e.response.status == 500) {
              resolve(500);
            } else {
              resolve(e.response.status + " Error");
            }
          }
        });
    });
  }
  @Action
  [Actions.UPDATE_STATUS](obj: any) {
    return new Promise((resolve, reject) => {
      const header = {
        headers: { Authorization: `Bearer ${obj.headers.token}` },
      };
      // console.log(header);
      // console.log(obj.values);
      axios
        .post(httpvalue + "/products/updateStatus", obj.values, header)
        .then((res) => {
          if (res.status == 401) {
            router.push({ name: "sign-in" });
          } else {
            resolve(res);

            toast.success(
              `${obj.values.product.product.Name
              } ${IntegrationProduct.friendlyName(
                obj.values.product
              )} üzerinde satışa ${obj.values.status == 1 ? "açıldı" : "kapatıldı"
              }`
            );
          }
        })
        .catch(async (e) => {
          if (e.response.status == 401) {
            resolve(401);
          } else if (e.response.status == 500) {
            toast.error(
              `${obj.values.product.product.Name
              } ${IntegrationProduct.friendlyName(
                obj.values.product
              )} üzerinde satışa ${obj.values.status == 1 ? "açılırken" : "kapatılırken"
              } hata oluştu, Hata Mesajı: ${e.response.data}`
            );
            resolve(e.response.data);
          } else {
            resolve(e.response.status + " Error");
          }
        });
    });
  }
  @Action
  [Actions.UPDATE_PRICE](obj: any) {
    return new Promise((resolve, reject) => {
      const token = getCookie("access_token");

      const header = {
        headers: { Authorization: `Bearer ${token}` },
      };
      // console.log(header);
      // console.log(obj.values);
      axios
        .post(httpvalue + "/products/updatePrice", {products :obj}, header)
        .then((res) => {
          if (res.status == 401) {
            router.push({ name: "sign-in" });
          } else {
            resolve(res);

            toast.success(
              "Fiyat değişiklik işlemi başarılı bir şekilde gerçekleşti"
            );
          }
        })
        .catch(async (e) => {
          if (e.response.status == 401) {
            resolve(401);
          } else if (e.response.status == 500) {
            toast.error(
              `Fiyat değişiklik talebi gönderilirken hata oluştu, Hata Mesajı: ${e.response.data}`
            );
            resolve(e.response.data);
          } else {
            resolve(e.response.status + " Error");
          }
        });
    });
  }
  @Action
  [Actions.LOGOUT]() {
    const route = useRouter();
    removeCookie("access_token");
    removeCookie("refresh_token");
    this.context.commit(Mutations.SET_INTEGRATIONS, []);
    this.context.commit(Mutations.SET_PROFILEDETAILS, {} as ProfileDetails);

    localStorage.clear();
  }

  @Action
  [Actions.VERIFY_AUTH](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("verify_token", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [Actions.GETCLOSEPRODUCTS](data: Array<any>) {
    return new Promise((resolve, reject) => {
      const closeproduct: any[] = [];
      data.forEach((e: ProductNGDTO) => {
        if (
          e.integrationProducts?.some(
            (e) => e.product?.Status == ProductStatus.Closed
          )
        ) {
          closeproduct.push(e);
        }
      });
      resolve(closeproduct);
    });
  }
  @Action
  [Actions.GETOPENPRODUCTS](data: Array<any>) {
    return new Promise((resolve, reject) => {
      const openproduct: any[] = [];
      data.forEach((e) => {
        if (
          e.integrationProducts?.some(
            (e) => e.product?.Status == ProductStatus.Open
          )
        ) {
          openproduct.push(e);
        }
      });
      resolve(openproduct);
    });
  }
  @Action
  [Actions.ADDNEWCREDENTIAL](payload) {
    return new Promise((resolve, reject) => {
      const token = getCookie("access_token");
      const header = {
        headers: { Authorization: `Bearer ${token}` },
      };
      axios
        .post(httpvalue + "/users/AddCredentials", payload, header)
        .then((res) => {
          console.log(res);
          if (res.status == 201) {
            resolve(res);
          } else {
            resolve(res.data.statusCode);
          }
        })
        .catch(async (e) => {
          if (!e.status) {
            resolve(502);
          } else {
            if (e.response.status == 401) {
              reject(e.response.status);
            } else {
              reject(500);
            }
          }
        });
    });
  }
  @Action
  [Actions.REPLYCOMMENT](payload) {
    return new Promise((resolve, reject) => {
      const token = getCookie("access_token");
      const header = {
        headers: { Authorization: `Bearer ${token}` },
      };
      axios
        .post(httpvalue + "/products/reply", payload, header)
        .then((response) => {
          if (response.status == 201) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          resolve(false);
        });
    });
  }
  @Action
  [Actions.GETINTEGRATIONS]() {
    return new Promise((resolve, reject) => {
      const token = getCookie("access_token");
      const header = {
        headers: { Authorization: `Bearer ${token}` },
      };
      axios
        .get(httpvalue + "/users/integrations", header)
        .then((response) => {
          if (response.status == 200) {
            this.context.commit(Mutations.SET_INTEGRATIONS, response.data);
            resolve(response.data);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          resolve([]);
        });
    });
  }

  @Action
  [Actions.UPDATE_INTEGRATION](data: Integration) {
    return new Promise((resolve, reject) => {
      const token = getCookie("access_token");
      const header = {
        headers: { Authorization: `Bearer ${token}` },
      };
      axios
        .post(httpvalue + "/users/updateintegration", data, header)
        .then((response) => {
          if (response.status == 201) {
            resolve(response.data);
          } else {
            reject(null);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.DELETE_INTEGRATION](data: Integration) {
    return new Promise((resolve, reject) => {
      const token = getCookie("access_token");
      const header = {
        headers: { Authorization: `Bearer ${token}` },
      };
      axios
        .post(httpvalue + "/users/deleteintegration", data, header)
        .then((response) => {
          if (response.status == 201) {
            resolve(response.data);
          } else {
            reject(null);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GETLOGS](skip = 0, limit = 20) {
    return new Promise((resolve, reject) => {
      const token = getCookie("access_token");
      const header = {
        headers: { Authorization: `Bearer ${token}` },
      };
      axios
        .post(httpvalue + "/users/latestlogs", {
          skip: skip,
          limit: limit
        }, header)
        .then((response) => {
          if (response.status == 201) {
            resolve(response.data);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          resolve([]);
        });
    });
  }

  @Action
  [Actions.MAKEPAYMENT](data: PaymentInfo){
    return new Promise((resolve, reject) => {
      const token = getCookie("access_token");
      const header = {
        headers: { Authorization: `Bearer ${token}` },
      };
      axios
        .post(httpvalue + "/users/makepayment", data, header)
        .then((response) => {
          if (response.status == 201) {
            resolve(response.data);
          } else {
            reject(null);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
    

}
