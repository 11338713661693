import { IntegrationType } from "./IntegrationType";

export class Integration {
    integrationType?: IntegrationType;
    id?: string;
    credentials?: Credentials | TrendyolCredentials;
    branches?: any;
    isNew? =false;
}
export abstract class Credentials {

    username?: string;

    password?: string;

    selectedBranch!: string;

    selectedMenuId?: string;
}
export class YemeksepetiCredentials extends Credentials {
}

export class PaketServisiCredentials extends Credentials {

}

export class GetirCredentials extends Credentials {

}

export class TrendyolCredentials extends Credentials {

    supplierId!: string;
    apiKey!: string;
    apiSecret!: string;
    selectedBranch!: string;
}