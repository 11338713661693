import { IntegrationProduct } from "./IntegrationProduct";
import { IntegrationType } from "./IntegrationType";
import { ProductStatus } from "./ProductStatus";

export class IIntegrationElement {
  integrationId: string | undefined;
  integrationType: IntegrationType | undefined;
  //Define a getter for twoletter of the integration name
  static twoLetter(val: IIntegrationElement) {
    switch (val.integrationType) {
      case IntegrationType.YemekSepeti:
        return "YS";
      case IntegrationType.Getir:
        return "GT";
      case IntegrationType.Trendyol:
        return "TY";
      case IntegrationType.PaketServisi:
        return "PS";
      case IntegrationType.MigrosYemek:
        return "MY";
      case IntegrationType.Fuddy:
        return "FD";
      case IntegrationType.GoFody:
        return "GF";
      default:
        return "XX";
    }
  }
  //Define a getter for the first letter of the integration name
  static firstLetter(val: IIntegrationElement) {
    switch (val.integrationType) {
      case IntegrationType.YemekSepeti:
        return "Y";
      case IntegrationType.Getir:
        return "G";
      case IntegrationType.Trendyol:
        return "T";
      case IntegrationType.PaketServisi:
        return "P";
      case IntegrationType.MigrosYemek:
        return "M";
      case IntegrationType.Fuddy:
        return "F";
      case IntegrationType.GoFody:
        return "G";
      default:
        return "X";
    }
  }
  // Define a getter for status color of the integration
  static statusColor(val: IIntegrationElement, status: ProductStatus) {
    if (status == ProductStatus.Open)
      switch (val.integrationType) {
        case IntegrationType.YemekSepeti:
          return "#ea024b";
        case IntegrationType.Getir:
          return "purple";
        case IntegrationType.Trendyol:
          return "orange";
        case IntegrationType.PaketServisi:
          return "blue";
        case IntegrationType.MigrosYemek:
          return "#f53c04";
        case IntegrationType.Fuddy:
          return "purple";
        case IntegrationType.GoFody:
          return "pink";
        default:
          return "black";
      }
    else return "gray";
  }
  // Define a static method for getting the friendly name of the integration
  static friendlyName(val: IIntegrationElement) {
    switch (val.integrationType) {
      case IntegrationType.YemekSepeti:
        return "Yemek Sepeti";
      case IntegrationType.Getir:
        return "Getir";
      case IntegrationType.Trendyol:
        return "Trendyol";
      case IntegrationType.PaketServisi:
        return "Paket Servisi";
      case IntegrationType.MigrosYemek:
        return "Migros Yemek";
      case IntegrationType.Fuddy:
        return "Fuddy";
      case IntegrationType.GoFody:
        return "GoFody";
      default:
        return "Unknown";
    }
  }
}
